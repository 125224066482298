import { AcTitle, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcIntlDDMMYYYYHHMM } from '@helpers/ac-intl-datetime.helper'
import styles from './ac-template-todo-soup-salad.module.scss'
import { AcSidebarSaladMenu } from '../ac-template-todo-menu/ac-template-salad-menu'
import { ITemplateTodoSoupSaladItem } from '@typings'

export interface IAcTemplateTodoSoupSalad {
    showSoup?: boolean,
    showSalad?: boolean
}

export const AcTemplateTodoSoupSalad = observer(({ showSoup, showSalad }: IAcTemplateTodoSoupSalad) => {
    const { template } = useStore()

    const renderOrderDateTime = useMemo(() => {
        if (!template?.current_todo_soup_salad?.data?.preference_date_time) return null
        const prettyDate = AcIntlDDMMYYYYHHMM(new Date(template?.current_todo_soup_salad?.data?.preference_date_time)) || '-'
        return <AcTypography element='span' color='neutral-500' size='xs'>{LABELS.SUBMITTED_PREFERENCES_AT}{prettyDate}</AcTypography>

    }, [template.current_todo_soup_salad?.data?.preference_date_time])

    const renderPreferenceRows = (list: ITemplateTodoSoupSaladItem[]) => {
        if (!list?.length) return null
        return list.map(
            (item: ITemplateTodoSoupSaladItem) => (
                <div key={item.id} className={styles['ac-template-todo-soup-salad-item']}>
                    <AcTypography element='span' size='sm' weight='light'>{item.name}</AcTypography>
                </div>
            )
        )
    }

    const renderEmpty = (label: string) => (
        <div className={styles['ac-template-todo-soup-salad-empty']}>
            <AcTitle
                level={3}
                className={styles['ac-template-todo-soup-salad-empty-title']}>
                {label}
            </AcTitle>
        </div>
    )

    const renderSoups = useMemo(
        () => {
            if (!showSoup) return null
            const soups = template.current_todo_soup_salad?.data?.soups
            return (
                <div className={styles['ac-template-todo-soup-salad__menu']}>
                    <AcTypography element='span' size='sm' weight='semibold'>{LABELS.SOUP}</AcTypography>
                    {!soups?.length ? renderEmpty(LABELS.NO_SOUP_AVAILABLE) : renderPreferenceRows(soups)}
                </div>
            )
        },
        [template.current_todo_soup_salad?.data?.soups]
    )

    const renderSalads = useMemo(
        () => {
            if (!showSalad) return null
            const salads = template.current_todo_soup_salad?.data?.salads
            return (
                <div className={styles['ac-template-todo-soup-salad__menu']}>
                    <AcTypography element='span' size='sm' weight='semibold'>{LABELS.SALAD}</AcTypography>
                    {!salads?.length ? renderEmpty(LABELS.NO_SALAD_AVAILABLE) : renderPreferenceRows(salads)}
                </div>
            )
        },
        [template.current_todo_soup_salad?.data?.salads]
    )

    return (
        <div className={styles['ac-template-todo-soup-salad']}>
            {renderOrderDateTime}
            {renderSoups}
            {renderSalads}
            <AcSidebarSaladMenu />
        </div>
    )
})
