import {
  AcAlert,
  AcAppHeader,
  AcButton,
  AcFormProvider,
  AcIcon,
  AcTab,
  AcTabs,
} from '@components'
import {
  GLOBAL_ERRORS,
  ICONS,
  KEYS,
  LABELS,
  PRODUCT_DETAIL_TABS,
  ROUTE_PATHS,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { IProductExtrasTab } from '@partials/tabs/product-detail/product-extras'
import { IProductInformationTab } from '@partials/tabs/product-detail/product-information'
import { IProductLocationTab } from '@partials/tabs/product-detail/product-location'
import { IProduct } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { generatePath, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
export const AcProductCreate = observer((): JSX.Element => {
  const { products } = useStore()
  const locationParams = useParams()
  const navigate = useNavigate()

  const [tabErrors, setTabErrors] = useState(new Set<string>())
  const [editMode, setEditMode] = useState(true)

  const handleSuccess = (newProduct: IProduct) => {
    return new Promise(resolve => {
      setTabErrors(new Set())
      setEditMode(false)
      form.reset(newProduct)
      toast(LABELS.SAVED_PRODUCT, {
        toastId: KEYS.SAVED_PRODUCT,
        icon: <AcIcon icon={ICONS.CHECK} />,
      })
      resolve(null)
    })
  }

  const handleErrors = (errors: Record<string, string[]>) => {
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as string, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )
  }

  const onSubmit = async (data: FieldValues) => {
    setTabErrors(new Set())
    await products
      .createProduct(data as IProduct)
      .then(async (newProduct: IProduct) => {
        await handleSuccess(newProduct)
        const path = generatePath(ROUTE_PATHS.PRODUCT_DETAIL, {
          id: newProduct.id.toString(),
        })
        navigate(path, { replace: true })
      })
      .catch(e => {
        handleErrors(e)
      })
  }

  const hasErrors = useMemo(() => !!tabErrors.size, [tabErrors])

  const setTabError = (name: string) => {
    setTabErrors(value => new Set(...value).add(name))
  }

  const form = useForm()

  const onFormSubmit = () => {
    form.handleSubmit(onSubmit)()
  }

  useEffect(() => {
    products.setForm(form)
    products.getJoins()
    return () => products.clearProduct()
  }, [locationParams?.id])

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.PRODUCT_LIST}
        active={products.product.active ?? false}
        title={products.product.name || ''}
        action={
          <AcButton
            onClick={editMode ? onFormSubmit : () => setEditMode(true)}
            label={editMode ? LABELS.SAVE : LABELS.MODIFY}
          />
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        key={locationParams.id}
        onSubmit={onFormSubmit}
        readOnly={!editMode}
        loading={products.loading}>
        <AcTabs
          tabs={PRODUCT_DETAIL_TABS}
          errors={tabErrors}>
          <AcTab<IProductInformationTab>
            name={KEYS.PRODUCT_INFORMATION}
            onError={() => setTabError(KEYS.PRODUCT_INFORMATION)}
          />
          <AcTab<IProductLocationTab>
            name={KEYS.PRODUCT_LOCATION}
            onError={() => setTabError(KEYS.PRODUCT_LOCATION)}
          />
          <AcTab<IProductExtrasTab>
            name={KEYS.PRODUCT_AVAILABILITY}
            onError={() => setTabError(KEYS.PRODUCT_AVAILABILITY)}
          />
          <AcTab<IProductExtrasTab>
            name={KEYS.PRODUCT_EXTRAS}
            onError={() => setTabError(KEYS.PRODUCT_EXTRAS)}
          />
        </AcTabs>
      </AcFormProvider>
    </>
  )
})
