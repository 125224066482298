import {
  AcAlert,
  AcAppHeader,
  AcButton,
  AcContainer,
  AcFormProvider,
  AcIcon,
  AcTab,
  AcTabs,
} from '@components'
import {
  GLOBAL_ERRORS,
  ICONS,
  KEYS,
  LABELS,
  PRODUCT_DETAIL_TABS,
  ROUTE_PATHS,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { IProductInformationTab } from '@partials/tabs/product-detail/product-information'
import { IProductLocationTab } from '@partials/tabs/product-detail/product-location'
import { IProductExtrasTab } from '@partials/tabs/product-detail/product-extras'
import { IProduct } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { IProductAvailabilityTab } from '@partials/tabs/product-detail/product-availability'
export const AcProductDetail = observer((): JSX.Element => {
  const { products, tags } = useStore()
  const locationParams = useParams()
  const [tabErrors, setTabErrors] = useState(new Set<string>())
  const [editMode, setEditMode] = useState(false)

  const handleSuccess = (newProduct: IProduct) => {
    setTabErrors(new Set())
    setEditMode(false)
    form.reset(newProduct)
    toast(LABELS.SAVED_PRODUCT, {
      toastId: KEYS.SAVED_PRODUCT,
      icon: <AcIcon icon={ICONS.CHECK} />,
    })
  }

  const handleErrors = (errors: Record<string, string[]>) => {
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as string, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )
  }

  const handleUpdate = async (data: FieldValues) => {
    setTabErrors(new Set())
    await products
      .updateProduct(data as IProduct)
      .then(updatedProduct => handleSuccess(updatedProduct as IProduct))
      .catch((e: any) => handleErrors(e))
  }

  const hasErrors = useMemo(() => !!tabErrors.size, [tabErrors])

  const setTabError = (name: string) => {
    setTabErrors(value => new Set(value).add(name))
  }

  const getDefaultValues = async () => {
    const id = parseInt(locationParams?.id || '')
    return await products.getById(id)
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const onFormSubmit = () => {
    form.handleSubmit(handleUpdate)()
  }

  useEffect(() => {
    products.setForm(form)
    return () => products.clearProduct()
  }, [])

  useEffect(() => {
    products.getJoins()
    tags.getList()
  }, [])

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.PRODUCT_LIST}
        active={products.product.active ?? false}
        title={products.product.name || ''}
        action={
          <>
            <AcButton
              icon={products.current_archived ? ICONS.CHECK : ICONS.X_CIRCLE}
              color="secondary"
              onClick={() => products.handleArchiveProduct()}
              label={
                products.current_archived
                  ? LABELS.ACTIVE_ACTION
                  : LABELS.ARCHIVE_ACTION
              }
            />
            <AcButton
              onClick={editMode ? onFormSubmit : () => setEditMode(true)}
              label={editMode ? LABELS.SAVE : LABELS.MODIFY}
            />
          </>
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        key={locationParams.id}
        onSubmit={onFormSubmit}
        readOnly={!editMode}
        loading={products.loading || tags.loading}>
        <AcTabs
          tabs={PRODUCT_DETAIL_TABS}
          errors={tabErrors}>
          <AcTab<IProductInformationTab>
            name={KEYS.PRODUCT_INFORMATION}
            onError={() => setTabError(KEYS.PRODUCT_INFORMATION)}
          />
          <AcTab<IProductLocationTab>
            name={KEYS.PRODUCT_LOCATION}
            onError={() => setTabError(KEYS.PRODUCT_LOCATION)}
          />
          <AcTab<IProductAvailabilityTab>
            name={KEYS.PRODUCT_AVAILABILITY}
            onError={() => setTabError(KEYS.PRODUCT_AVAILABILITY)}
          />
          <AcTab<IProductExtrasTab>
            name={KEYS.PRODUCT_EXTRAS}
            onError={() => setTabError(KEYS.PRODUCT_EXTRAS)}
          />
        </AcTabs>
      </AcFormProvider>
    </>
  )
})
