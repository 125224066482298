import { KEYS, ROLES, STORE_KEYS, } from '@constants'
import { Store } from '@stores'
import { ISingleUser, ISingleUserCreate, IRole, ISingleCustomerCompact } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { DataGridStore } from './datagrid.stores'
import { LABELS } from '@constants'

export const defaultProfileValues: ISingleUserCreate = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  phone: '',
  customer_id: null,
  roles: [],
  roles_contact: [],
  roles_employee: [],
  receives_tickets: false,
}

export class UsersStore {
  _store: Store
  loading: boolean
  available_roles: IRole[] | null
  data_grid_employees: DataGridStore
  data_grid_customers: DataGridStore
  current_profile: ISingleUser | null
  customers_list: ISingleCustomerCompact[] | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.available_roles = null
    this.data_grid_employees = new DataGridStore(this._store, STORE_KEYS.USERS_EMPLOYEES)
    this.data_grid_customers = new DataGridStore(this._store, STORE_KEYS.USERS_CONTACTS)
    this.current_profile = null
    this.customers_list = null
  }

  @computed
  get is_loading(): boolean {
    return this.loading
  }

  @computed
  get watch_current_profile(): ISingleUser | null {
    return this.current_profile
  }

  @computed
  get watch_available_roles(): IRole[] | null {
    return this.available_roles
  }

  @computed
  get watch_archived(): boolean | undefined {
    return this.current_profile?.deleted_at ? true : false
  }

  @action
  getRoles = async () => {
    try {
      const res = await this._store.api.users.get_roles()

      const mapRoleLabels = res.data.map(role => ({
        label: ROLES[role.name as keyof typeof ROLES],
        value: role.id,
        id: role.id,
        name: role.name,
        group: role.group
      }))
      this._store.set(STORE_KEYS.USERS, 'available_roles', mapRoleLabels)
    } catch (e: any) {
      console.log(e)
    }
  }

  @action
  splitRoles = (profile: ISingleUser) => {
    const roles = profile.roles?.map(({id, name, group}) => ({
      id,
      name,
      group,
      label: ROLES[name as keyof typeof ROLES],
      value: id,
    }))

    const newProfile = {
      ...profile, 
      roles_contact: roles?.length ? roles.filter(({ group }) => group === 'contact') : [],
      roles_employee: roles?.length ? roles.filter(({ group }) => group === 'employee') : [],
    }
    
    delete newProfile.roles

    return newProfile
  }

  @action
  mergeRoles = (profile: any) => {
    // Need to create a new object reference, not the original payload object
    // Otherwise the delete methods for roles_contact and roles_employee will mess up the form data
    const payload = {...profile}
    if (!payload?.roles_contact?.length) {
      payload.roles_contact = []
    }
    if (!payload?.roles_employee?.length) {
      payload.roles_employee = []
    }
    payload.roles = payload.roles_contact?.concat(payload.roles_employee)?.map(({name}: IRole) => name)
    delete payload.roles_contact
    delete payload.roles_employee
    return payload
  }

  @action
  createProfile = async (data: ISingleUserCreate) => {
    this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, true)
    try {
      const res = await this._store.api.users.create_profile(this.mergeRoles(data))
      const profile = this.splitRoles(res.data)
      return Promise.resolve(profile)
    } catch (e: any) {
      return Promise.reject(e?.response?.data?.errors)
    } finally {
      this._store.set(STORE_KEYS.USERS, KEYS.LOADING, false)
    }

  }

  @action
  getProfile = async (id: ISingleUser['id']) => {
    this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, true)
    this._store.set(STORE_KEYS.USERS, 'current_profile', null)

    try {
      const res = await this._store.api.users.get_profile(id)
      const profile = this.splitRoles(res.data)
      this._store.set(STORE_KEYS.USERS, 'current_profile', profile)
      return Promise.resolve(profile)
    } catch (e: any) {
      return Promise.reject(e?.response?.data?.errors)
    } finally {
      this._store.set(STORE_KEYS.USERS, KEYS.LOADING, false)
    }
  }

  @action
  updateProfile = async (payload: ISingleUser) => {
    this._store.set(STORE_KEYS.CUSTOMER, KEYS.LOADING, true)
    try {
      const res = await this._store.api.users.update_profile(payload.id, this.mergeRoles(payload))
      const profile = this.splitRoles(res.data)
      this._store.set(STORE_KEYS.USERS, 'current_profile', profile)
      if (res?.data.id === this._store.auth.current_employee?.id) {
        this._store.set(STORE_KEYS.AUTH, 'employee', res.data as any, true)
      }
      return Promise.resolve(profile)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.USERS, KEYS.LOADING, false)
    }
  }

  @action
  archiveProfile = async (id: ISingleUser['id']) => {
    this._store.set(STORE_KEYS.USERS, KEYS.LOADING, true)

    try {
      await this._store.ui.confirm({ title: LABELS.USER_ARCHIVE_TITLE, content: LABELS.USER_ARCHIVE_MODAL, confirmLabel: LABELS.USER_ARCHIVE_CONFIRM })
      const res = await this._store.api.users.archive_profile(id)
      const profile = this.splitRoles(res.data)
      this._store.set(STORE_KEYS.USERS, 'current_profile', profile)
      return Promise.resolve(res)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.USERS, KEYS.LOADING, false)
    }
  }

  @action
  restoreProfile = async (id: ISingleUser['id']) => {
    this._store.set(STORE_KEYS.USERS, KEYS.LOADING, true)

    try {
      await this._store.ui.confirm({ title: LABELS.USER_ACTIVATE_TITLE, content: LABELS.USER_ACTIVATE_MODAL, confirmLabel: LABELS.USER_ACTIVATE_CONFIRM })
      const res = await this._store.api.users.restore_profile(id)
      const profile = this.splitRoles(res.data)
      this._store.set(STORE_KEYS.USERS, 'current_profile', profile)
      return Promise.resolve(res)
    } catch (e: any) {
      return Promise.reject(e.response.data.errors)
    } finally {
      this._store.set(STORE_KEYS.USERS, KEYS.LOADING, false)
    }
  }

  @action
  clearCurrentProfile = () => {
    this._store.set(STORE_KEYS.USERS, 'current_profile', null)
  }

  @action
  getCustomersList = async () => {
    try {
      const res = await this._store.api.customers.get_list()
      if (res && res.length) {
        const list = res.filter((res: ISingleCustomerCompact) => res.name && res.id)
        this._store.set(STORE_KEYS.USERS, 'customers_list', list)
      return Promise.resolve(list)
      }
    } catch (e: any) {
      return Promise.reject(e?.response?.data?.errors)
    }
  }

  @action
  getSingleCustomer = async (id: number) => {
    try {
      const res = await this._store.api.customers.get_single(id)
      return Promise.resolve(res)
    } catch (e: any) {
      return Promise.reject(e?.response?.data?.errors)
    }
  }
}
