import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { AcButton, AcTemplateTodoFinished, AcTypography } from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { AcIntlDDMMYYYY, AcIntlWeekday } from '@helpers/ac-intl-datetime.helper'
import { useStore } from '@hooks/use-store'
import { TemplateSaladMenuEdit } from '@partials/modals/index.modals'

import styles from './ac-template-salad-menu.module.scss'

export const AcSidebarSaladMenu = observer(() => {
  const { ui, template } = useStore()

  const renderUpdatedAt = useMemo(() => {
    if (!template.current_todo_soup_salad?.data?.message?.updated_at)
      return DYNAMIC_LABELS.TEMPLATE_TODO_MENU_EMPTY(template.current_week)

    const updatedDate = new Date(template.current_todo_soup_salad.data?.message?.updated_at)
    const updatedDateString = `
      ${AcIntlWeekday(updatedDate)} 
      ${AcIntlDDMMYYYY(updatedDate)}`

    return DYNAMIC_LABELS.TEMPLATE_TODO_MENU_FILLED(updatedDateString)
  }, [template.current_todo_soup_salad, template.current_week])

  const renderMenuContent = useMemo(() => {
    if (!template?.current_todo_soup_salad?.data?.message?.message) return null
    return template?.current_todo_soup_salad?.data?.message?.message
  }, [template?.current_todo_soup_salad?.data?.message])

  const renderMenuButtonText = useMemo(
    () =>
      template.current_todo_soup_salad?.state === 'DONE'
        ? LABELS.CHANGE_MENU
        : LABELS.MAKE_MENU,
    [template.current_todo_soup_salad]
  )

  const handleMenuButtonClick = async () =>
    await ui
      .showModal(TemplateSaladMenuEdit, { width: 'lg' })
      .catch(() => void 0)

  const handleFinishButtonClick = () => template.completeTodo('SOUP_SALAD')

  return (
    <div>
      <AcTypography
        size="sm"
        weight='semibold'
        color='neutral-900'>
        {LABELS.SALAD_MENU}
      </AcTypography>
      <AcTypography
        size="xs"
        color='neutral-500'>
        {renderUpdatedAt}
      </AcTypography>
      {renderMenuContent && (
        <AcTypography
          size='sm'
          weight='light'>
          {renderMenuContent}
        </AcTypography>
      )}
      <div className={styles['ac-template-salad-menu-button-wrapper']}>
        <AcButton
          label={renderMenuButtonText}
          variant="text"
          color="neutral-700"
          onClick={handleMenuButtonClick}
          withToolTip={false}
          padding="none"
          disabled={!template.user_can_edit}
        />
        {template.current_todo_soup_salad?.state === 'DONE' ? (
          <AcTemplateTodoFinished />
        ) : (
          <AcButton
            label={LABELS.FINISH_TASK}
            variant="text"
            color="primary"
            onClick={handleFinishButtonClick}
            withToolTip={false}
            padding="none"
            disabled={!template.user_can_edit}
          />
        )}
      </div>
    </div>
  )
})
