import { AcForm, AcFormCard, AcFormInput } from '@components'
import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { IRole, ISingleUser } from '@typings'
import styles from './user-profile-form.module.scss'

export type IUserProfileForm = {
  onError: () => undefined | void
  isNewProfile?: boolean
}

export const UserProfileForm = observer(
  ({ onError, isNewProfile = false }: IUserProfileForm) => {
    const FormInput = AcFormInput<ISingleUser>

    const { users, auth } = useStore()


    const handleRoleOptionPermissionCheck = (role: IRole) => {
      const userPermissions = auth?.current_employee?.permissions
      if (!userPermissions?.includes(`user.roles.manage.${role.name}`)) {
        return { ...role, disabled: true }
      } else {
        return role
      }
    }
    const getCustomers = useMemo(() => {
      if (!users?.customers_list) return []
      return users.customers_list.map(customer => ({ value: customer.id, label: customer.name }))
    }, [users.customers_list])

    const getEmployeeRoles = useMemo(() => {
      if (!users.watch_available_roles?.length) return []
      return users.watch_available_roles.filter(role => role.group === 'employee').map(role => handleRoleOptionPermissionCheck(role))
    }, [users.watch_available_roles])

    const getContactRoles = useMemo(() => {
      if (!users.watch_available_roles?.length) return []
      return users.watch_available_roles.filter(role => role.group === 'contact').map(role => handleRoleOptionPermissionCheck(role))
    }, [users.watch_available_roles])

    const prettyDate = (input: string | number | readonly string[] | undefined) => {
      return input ? dayjs(input as string).format('DD-MM-YYYY HH:mm') : ''
    }

    useEffect(() => {
      users.getRoles()
      users.getCustomersList()
    }, [])


    return (
      <AcForm onError={onError} layout='column'>
        <div className={styles['user-profile-form']}>
          <AcFormCard title={LABELS.USER_OVERVIEW}>
            <FormInput
              name={KEYS.FIRST_NAME}
              label={LABELS.NAME_FIRST}
              validate='required'
            />
            <FormInput
              name={KEYS.MIDDLE_NAME}
              label={LABELS.NAME_PREFIX}
            />
            <FormInput
              name={KEYS.LAST_NAME}
              label={LABELS.NAME_LAST}
              validate='required'
            />
            <FormInput
              name={KEYS.EMAIL}
              label={LABELS.EMAIL}
              validate='required'
            />
            <FormInput
              name={KEYS.PHONE}
              label={LABELS.PHONE}
            />
            <FormInput
              name={KEYS.CUSTOMER_ID}
              label={LABELS.ORGANISATIONS}
              options={getCustomers}
              type='select'
              withSearch
              labelKey="label"
              valueKey="value"
              inputFormat="value"
              outputFormat="value"
            />
          </AcFormCard>
          <div className={styles['user-profile-form__col-right']}>
            <AcFormCard title={LABELS.ROLES_PERMISSIONS}>
              <FormInput
                name={KEYS.ROLES_EMPLOYEE}
                label={LABELS.ROLES_EMPLOYEE}
                labelKey="label"
                valueKey="value"
                type='select-chip'
                options={getEmployeeRoles}
                transform={{
                  input: (value: any) => value.id
                }}
              />
              <FormInput
                name={KEYS.DELIVERY_RECEIPT}
                label={LABELS.DELIVERY_RECEIPT}
                type='switch'
              />
              <FormInput
                name={KEYS.ROLES_CONTACT}
                label={LABELS.ROLES_CLIENT}
                labelKey="label"
                valueKey="value"
                type='select-chip'
                options={getContactRoles}
                transform={{
                  input: (value: any) => value.id
                }}
              />
            </AcFormCard>
            {!isNewProfile && (
              <AcFormCard title={LABELS.DATES}>
                <FormInput
                  name={KEYS.CREATED_AT}
                  label={LABELS.CREATED}
                  readOnly
                  transform={{
                    input: (value) => prettyDate(value)
                  }}
                />
                <FormInput
                  name={KEYS.UPDATED_AT}
                  label={LABELS.LAST_UPDATED}
                  readOnly
                  transform={{
                    input: (value) => prettyDate(value)
                  }}
                />
              </AcFormCard>
            )}
          </div>
        </div>
      </AcForm>
    )
  }
)
