import {
  AcCard,
  AcCheckbox,
  AcChip,
  AcDataGridCell,
  AcDataGridFilters,
  AcDataGridHeader,
  AcDataGridPagination,
  AcDataGridRow,
  AcTooltip,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { IDataGridDataItem, IDataGridField } from '@typings'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { NavigateOptions, generatePath, useNavigate } from 'react-router-dom'
import { DataGridStore } from 'src/stores/datagrid.stores'
import { AcSwitch } from '../ac-switch/ac-switch'
interface IAcDataGrid {
  store: DataGridStore
  detailPath: string
  navigateOptions?: NavigateOptions
}

export const AcDataGrid = observer(
  ({ store, detailPath, navigateOptions }: IAcDataGrid) => {
    const navigate = useNavigate()
    const hasData = useMemo(() => store.data.length > 0, [store.data])

    const handleRowClick = (
      resource_key: IDataGridDataItem['resource_key'],
      e: React.MouseEvent
    ) => {
      e.stopPropagation()
      const path = generatePath(detailPath, { id: resource_key })

      if (e.ctrlKey || e.button === 1) {
        window.open(path)
      } else {
        return navigate(path, navigateOptions)
      }
    }

    const handleBooleanClick = (
      { resource_key }: IDataGridDataItem,
      column: IDataGridField
    ) => store.handleCallBack(resource_key, column.callback)

    const getCellComponent = useCallback(
      (column: IDataGridField, item: IDataGridDataItem) => {
        switch (column.type) {
          case 'text':
            return (
              <AcTypography
                size="sm"
                element="span"
                weight={column.meta?.styling?.bold ? 'semibold' : 'light'}>
                {item[column.field] ?? '-'}
              </AcTypography>
            )

          case 'price':
            return (
              <AcTypography
                element="span"
                size="sm"
                weight={column.meta?.styling?.bold ? 'semibold' : 'light'}>
                {column.meta.currency} {item[column.field] ?? '-'}
              </AcTypography>
            )

          case 'boolean':
            return (
              <AcTooltip label={column.meta?.tooltip[item[column.field]]}>
                <AcSwitch
                  size="sm"
                  loading={store.isBusy.includes(item.resource_key)}
                  checked={item[column.field]}
                  onClick={() => handleBooleanClick(item, column)}
                />
              </AcTooltip>
            )
          case 'chip': {
            const label = item[column.field]
            return (
              <AcChip
                variant={column.meta.style_mapping[label.toLowerCase()]}
                label={label}
              />
            )
          }
          //@TODO date format
          case 'date':
            return (
              <AcTooltip
                visible={!column.meta.hasNoTooltip}
                label={dayjs(item.modified || item.updated_at).format(
                  column.meta.format.date_time
                )}>
                <AcTypography
                  size="sm"
                  weight="light"
                  element="span">
                  {dayjs(item.modified || item.updated_at).format(column.meta.format.date)}
                </AcTypography>
              </AcTooltip>
            )
          //@TODO Temp add default
          default:
            return (
              <AcTypography
                size="sm"
                weight="light"
                element="span">
                {item[column.field]}
              </AcTypography>
            )
        }
      },
      [store.isBusy]
    )

    useEffect(() => {
      store.getData()
    }, [store.query, store.activeFilters, store.activeSort])

    useEffect(() => {
      return () => store.reset()
    }, [])

    return (
      <AcCard padding="none">
        <table style={{ width: '100%' }}>
          <AcDataGridFilters store={store} />
          <AcDataGridHeader store={store} />
          {!hasData && !store.loading && (
            <AcDataGridRow>
              <AcDataGridCell>
                <AcTypography>{LABELS.NO_RESULTS}</AcTypography>
              </AcDataGridCell>
            </AcDataGridRow>
          )}
          {hasData &&
            store.data.map(singleItem => {
              return (
                <AcDataGridRow
                  key={singleItem.resource_key}
                  onClick={(e: React.MouseEvent) =>
                    handleRowClick(singleItem.resource_key, e)
                  }>
                  {store.has_actions && (
                    <AcDataGridCell>
                      <AcCheckbox
                        label={LABELS.SELECT_THIS_ROW}
                        checked={store.selectedValues.includes(
                          singleItem.resource_key
                        )}
                        onClick={() =>
                          store.handleSelection(singleItem.resource_key)
                        }
                      />
                    </AcDataGridCell>
                  )}
                  {store.fields.map(singleField => {
                    if (singleField.field === 'id-check') return
                    return (
                      <AcDataGridCell
                        key={`${singleField.field}-${singleItem.name}`}
                        style={{ flex: singleField.flex }}>
                        {getCellComponent(singleField, singleItem)}
                      </AcDataGridCell>
                    )
                  })}
                </AcDataGridRow>
              )
            })}
          {store.pagination?.has_pages && (
            <AcDataGridPagination
              pagination={store.pagination}
              store={store}
            />
          )}
        </table>
      </AcCard>
    )
  }
)
